import {ResearchProjectDto} from "../../../../shared/generated/model/research-project-dto";
import {ResearchProjectAreaParkListDto} from "../../../../shared/generated/model/research-project-area-park-list-dto";
import {
    ResearchPermitPublicFormProgressIndicatorComponent
} from "../../../research-permit-public-form-progress-indicator/research-permit-public-form-progress-indicator.component";
import {Title} from "@angular/platform-browser";
import {UserDto} from "../../../../shared/generated/model/user-dto";
import {RoleEnum} from "../../../../shared/generated/enum/role-enum";
import {ResearchProjectStatusTypeEnum} from "../../../../shared/generated/enum/research-project-status-type-enum";
import {Router} from "@angular/router";
import {
    BasicConfigurableModalComponent
} from "../../../basic/basic-configurable-modal/basic-configurable-modal.component";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {Alert} from "../../../../shared/models/alert";
import {AlertContext} from "../../../../shared/models/enums/alert-context.enum";
import {FormPage} from "../../../basic/shared/basic-form/basic-form.component";
import {NgForm} from "@angular/forms";

export abstract class ResearchProjectBaseComponentComponent {
    isLoading: boolean = false;
    stepSelectedRoute: string = '';
    canEditPage: boolean = false;
    title: string = '';
    researchProject: ResearchProjectDto;
    user: UserDto;
    canWithdrawStatuses = [
        ResearchProjectStatusTypeEnum.Draft,
        ResearchProjectStatusTypeEnum.InReview,
        ResearchProjectStatusTypeEnum.ReturnedToResearcher
    ];
    abstract pageSpecificTitle;
    abstract stepIndex: number;
    abstract handleStepSelect(route: string): void;
    abstract handleStatusChangeEvent(): void;
    abstract handleWithdraw(): void;


    public incompleteSectionsExcludingReviewAndSubmitExist(researchProject: ResearchProjectDto| ResearchProjectAreaParkListDto) {
        if (!researchProject) return true;
        if (!researchProject.IncompleteSectionNumbers) return false;
        return !!researchProject.IncompleteSectionNumbers
            .filter(x => x != ResearchPermitPublicFormProgressIndicatorComponent.REVIEW_AND_SUBMIT_STEP_INDEX).
            length;
    }

    public setCanEditPage(userDto: UserDto, projectDto: ResearchProjectDto, router: Router = null) {
        if (userDto.Role.RoleID == RoleEnum.Public) {
            this.canEditPage = projectDto.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Draft ||
                projectDto.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher;
        } else if (userDto.Role.RoleID == RoleEnum.SystemAdmin) {
            this.canEditPage = projectDto.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Withdrawn
                && projectDto.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Draft
                && projectDto.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Rejected
            ;
        } else if (projectDto?.AssignedTechnicalReviewerUserID == userDto.UserID) {
            this.canEditPage = projectDto.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.InReview
            || projectDto.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher
            || projectDto.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.AwaitingAnnualReport
        }
        else {
            this.canEditPage = false;
        }
        if (!!router) this.redirectIfNonViewable(userDto, projectDto, router);
    }

    private redirectIfNonViewable(userDto: UserDto, projectDto: ResearchProjectDto, router: Router) {
        if (!projectDto || !userDto) return;
        let restrictedStatuses = [
            ResearchProjectStatusTypeEnum.ActivePermit,
            ResearchProjectStatusTypeEnum.Expired,
            ResearchProjectStatusTypeEnum.Rejected,
            ResearchProjectStatusTypeEnum.Withdrawn,
        ];
        let viewableByPublicUser = userDto.Role.RoleID == RoleEnum.Public && (projectDto.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher ||
            projectDto.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Draft);
        let viewableBySysAdmin = userDto.Role.RoleID == RoleEnum.SystemAdmin && !restrictedStatuses.includes(projectDto.ResearchProjectStatusTypeID);
        let viewableByTechnicalReviewer = projectDto.AssignedTechnicalReviewerUserID == userDto.UserID && !restrictedStatuses.includes(projectDto.ResearchProjectStatusTypeID);

        if (viewableByPublicUser || viewableBySysAdmin || viewableByTechnicalReviewer) {
            return;
        }
        let url = `/public/research-project-review-and-submit/${projectDto.ResearchProjectID}`;
        router.navigateByUrl(url)
            .then();
    }

    withdraw(dialog, researchProjectID, researchProjectService, router, alertService){
        let dialogRef = dialog.open(BasicConfigurableModalComponent, {
            maxWidth: 600,
            data: {
                ID: researchProjectID,
                dto: {
                    RejectionMessage: null
                },
                header: 'Confirm Application Withdrawal',
                content: 'Warning: Withdrawing your application is a permanent action. Once withdrawn, your application will be locked and cannot be modified',
                showActions: true,
                editMode: true,
                saveButtonLabel: 'Confirm Withdrawal',
                saveButtonClass: 'danger',
                formPage: {
                    dtoName: 'permit',
                    elements: [
                        {
                            class: 'g-col-12',
                            name: 'WithdrawText',
                            label: 'To confirm, please type "withdraw" in the box below and click "Confirm Withdrawal"',
                            type: 'text',
                            required: true
                        },
                    ],
                    createUpsertDto: (dto) => {
                        return {WithdrawText: null};
                    },
                    put: (id, dto) => {
                        return researchProjectService.researchProjectsResearchProjectIDWithdrawPut(id)
                            .pipe(catchError(err => throwError(err)))
                    },
                } as unknown as FormPage,
                formInvalidCondition: (form: NgForm) => {
                    return form?.form?.get('WithdrawText')?.value?.toLowerCase() != 'withdraw';
                }
            }
        });

        if (dialogRef) {
            dialogRef.afterClosed().subscribe((response) => {
                if (response.submit) {
                    router.navigateByUrl("/permits")
                        .then(() => alertService.pushAlert(new Alert(`Permit application was successfully withdrawn.`, AlertContext.Success)));
                }
            });
        }
    }

    displayWithdrawButton() {
        return this.canMoveToWithdrawnStatus() && this.user?.Role.RoleID == RoleEnum.Public;
    }

    canMoveToWithdrawnStatus() {
        return this.canWithdrawStatuses.includes(this.researchProject?.ResearchProjectStatusTypeID);
    }

    protected constructor(protected titleService: Title) {
    }

    protected setTitle(researchProject: ResearchProjectDto){
        if (!researchProject) return;
        let title = this.pageSpecificTitle;
        if (!!researchProject.RenewedFromPermitNumber) title += ' - Renewal'
        this.title = title + ' – ' + researchProject.ApplicationNumber;
        this.titleService.setTitle(this.title);
    }
}
